body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bevel-up {
  box-shadow:
    inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5),
    inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.5);
}

.bevel-down {
  box-shadow:
    0.2em 0.2em 0.2em 0 rgba(0, 0, 0, 0.5),
    -0.2em -0.2em 0.2em 0 rgba(255, 255, 255, 0.5);
}
